import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { RcDevModeBannerComponent } from '../components/rc-mode-dev-banner/rc-dev-mode-banner.component';

@Component({
  selector: 'rc-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RcDevModeBannerComponent, ToastModule, RouterOutlet],
})
export class RcAppComponent implements OnInit {
  public ngOnInit(): void {
    if (this.checkIsIOS()) {
      this.addMaximumScaleToMetaViewport();
    }
  }

  // Workaround for iOS devices autoscaling on input select
  // https://stackoverflow.com/a/57527009/676013
  private addMaximumScaleToMetaViewport = (): void => {
    const el = document.querySelector('meta[name=viewport]');

    if (el !== null) {
      let content = el.getAttribute('content');
      const re = /maximum-scale=[0-9.]+/g;

      if (content && re.test(content)) {
        content = content.replace(re, 'maximum-scale=1.0');
      } else {
        content = [content, 'maximum-scale=1.0'].join(', ');
      }

      el.setAttribute('content', content);
    }
  };

  // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
  private checkIsIOS = (): boolean =>
    // @ts-expect-error: Ignore window typing
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}
