import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { RcUiButtonComponent } from './components/button/button.component';
import { RcUiFooterComponent } from './components/footer/footer.component';
import { RcMetricCardComponent } from './components/metric-card/metric-card.component';
import { RcUiNavbarHorizontalComponent } from './components/navbar/horizontal/horizontal.component';
import { RcUiNavbarVerticalComponent } from './components/navbar/vertical/vertical.component';
import { RcUiSideNavLayoutComponent } from './layouts/side-nav/side-nav.component';
import { RcUiTopNavLayoutComponent } from './layouts/top-nav/top-nav.component';

const PRIMENG_PROVIDERS = [ConfirmationService];

const RC_UI_PRIVATE_COMPONENTS = [
  RcUiFooterComponent,
  RcUiNavbarVerticalComponent,
  RcUiNavbarHorizontalComponent,
];

const RC_UI_PUBLIC_COMPONENTS = [
  RcUiTopNavLayoutComponent,
  RcUiSideNavLayoutComponent,
  RcUiButtonComponent,
];

const RC_STANDALONE_PUBLIC_COMPONENTS = [RcMetricCardComponent];

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (primeNGConfig: PrimeNGConfig) => {
        return (): void => {
          primeNGConfig.ripple = true;
        };
      },
      deps: [PrimeNGConfig],
    },
    ...PRIMENG_PROVIDERS,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ...RC_STANDALONE_PUBLIC_COMPONENTS,
    ...RC_UI_PUBLIC_COMPONENTS,
    ...RC_UI_PRIVATE_COMPONENTS,
  ],
  exports: [...RC_UI_PUBLIC_COMPONENTS, ...RC_STANDALONE_PUBLIC_COMPONENTS],
})
export class RcUiKitModule {}
