import { APP_INITIALIZER, NgModule } from '@angular/core';
import { provideClarity } from 'ngx-clarity';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { environment } from '../../environments/environment';
import { TrackingService } from './tracking.sevice';

const IMPORTS = [];

if (environment.tracking.enabled) {
  IMPORTS.push(
    NgxGoogleAnalyticsModule.forRoot(
      environment.tracking.trackers.googleAnalytics.measurementId,
    ),
  );
}

@NgModule({
  imports: IMPORTS,
  providers: [
    TrackingService,
    provideClarity({
      enabled: environment.tracking.enabled,
      projectId: environment.tracking.trackers.clarity.projectId,
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: (trackingService: TrackingService) => {
        return async function (): Promise<void> {
          if (environment.tracking.enabled) {
            await trackingService.init();
          }
        };
      },
      multi: true,
      deps: [TrackingService],
    },
  ],
})
export class RcTrackingModule {}
