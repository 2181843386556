import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: '',
    loadChildren: () => import('./modules/landing/landing.routes'),
  },
  {
    path: 'resources',
    loadChildren: () => import('./modules/cms/cms.routes'),
  },
  {
    path: 'manager',
    loadChildren: () =>
      import('./modules/manager/manager.routes').then((m) => m.default),
  },
  {
    path: 'tenant',
    loadChildren: () => import('./modules/tenant/tenant.routes'),
  },
  {
    path: 's',
    loadChildren: () => import('./modules/shortener/shortener.routes'),
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.routes'),
  },
  {
    path: '404',
    loadComponent: () =>
      import('./pages/404/404.component').then((m) => m.Rc404PageComponent),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./pages/404/404.component').then((m) => m.Rc404PageComponent),
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
];
