import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';

import { KindeAuthLoginCallbackComponent } from './kinde/components/kinde-login-callback.component';
import { KindeAuthService } from './kinde/kinde-auth.service';
import { MessageCodeAuthService } from './message-code/message-code-auth.service';

@NgModule({
  providers: [
    KindeAuthService,
    MessageCodeAuthService,
    {
      provide: APP_INITIALIZER,
      useFactory:
        (authService: KindeAuthService) => async (): Promise<void> => {
          await authService.initialize();
        },
      deps: [KindeAuthService],
      multi: true,
    },
  ],
  imports: [CommonModule, KindeAuthLoginCallbackComponent],
})
export class RcAuthModule {
  constructor(@Optional() @SkipSelf() parentModule?: RcAuthModule) {
    if (parentModule) {
      throw new Error(
        'RcAuthModule is already loaded. Import it once at root only',
      );
    }
  }
}
